import React, { ChangeEvent, useState } from "react";
import { motion } from "framer-motion";
import Header from "@components/Header";
import { FaHeart, FaComment, FaShareSquare, FaBookmark } from "react-icons/fa";

export default function InstagramStylePage() {
  const [uploadedImage, setUploadedImage] = useState<string | null>(null);
  const [captions, setCaptions] = useState([
    "Living my best life!",
    "Adventures await",
    "Feeling blessed",
    "Creating memories",
    "Chasing dreams",
  ]);
  const [selectedCaption, setSelectedCaption] = useState("");

  const handleImageUpload = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e?.target?.files?.[0];
    if (file) setUploadedImage(URL.createObjectURL(file));
  };

  const handleCaptionClick = (caption: string) => {
    setSelectedCaption(caption);
  };

  return (
    <div className="min-h-screen bg-gray-100 p-8">
      <Header
        title="Instagram Caption Viewer + Generator"
        description="Check how you look before uploading to instagram"
      />
      <motion.h1
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        className="text-4xl font-bold text-center mb-8"
      >
        Instagram-Style Image Upload
      </motion.h1>
      <div className="max-w-2xl mx-auto">
        <input
          type="file"
          accept="image/*"
          onChange={handleImageUpload}
          className="mb-4"
        />
        {uploadedImage && (
          <motion.div
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            className="mb-8"
          >
            <h2 className="text-2xl font-semibold mb-4">Choose a caption:</h2>
            <ul className="space-y-2">
              {captions.map((caption, index) => (
                <motion.li
                  key={index}
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  className="bg-white p-4 rounded-lg shadow cursor-pointer"
                  onClick={() => handleCaptionClick(caption)}
                >
                  {caption}
                </motion.li>
              ))}
            </ul>
          </motion.div>
        )}
        {uploadedImage && selectedCaption && (
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            className="bg-white rounded-lg shadow-lg overflow-hidden"
          >
            <div className="p-4 flex items-center">
              <div className="w-10 h-10 rounded-full bg-gray-300 mr-4"></div>
              <span className="font-semibold">username</span>
            </div>
            <img
              src={uploadedImage}
              alt="Uploaded image"
              width={600}
              height={600}
            />
            <div className="p-4">
              <div className="flex justify-between mb-4">
                <div className="flex space-x-4">
                  <FaHeart className="text-2xl text-gray-500 hover:text-red-500 cursor-pointer" />
                  <FaComment className="text-2xl text-gray-500 hover:text-blue-500 cursor-pointer" />
                  <FaShareSquare className="text-2xl text-gray-500 hover:text-green-500 cursor-pointer" />
                </div>
                <FaBookmark className="text-2xl text-gray-500 hover:text-yellow-500 cursor-pointer" />
              </div>
              <p className="font-semibold mb-2">1,234 likes</p>
              <p>
                <span className="font-semibold mr-2">username</span>
                {selectedCaption}
              </p>
              <p className="text-gray-500 mt-2">View all 56 comments</p>
              <p className="text-gray-400 text-xs mt-2">2 HOURS AGO</p>
            </div>
          </motion.div>
        )}
      </div>
    </div>
  );
}
